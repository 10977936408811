<template>
    <div>
        <v-card
            class="gst-event-info-modal d-flex flex-column u-height-100 overflow-y-hidden"
            :class="{
                'rounded-t-1': $vuetify.breakpoint.mdAndUp,
                'rounded-t-lg': !$vuetify.breakpoint.mdAndUp,
            }"
            color="text">
            <HeaderVariant1
                class="flex-grow-0 header"
                :title="$t( 'title' )"
                @close="$emit('close')" />
            <div
                class="gst-event-info-modal  gst-event-info-modal--no-tabs d-flex flex-column u-height-100">
                <div class="u-height-100 d-flex flex-column">
                    <DataLoading v-if="loading" class="mt-5 mb-5 pt-5 pb-5" />
                    <template v-else>
                        <div class="flex-grow-1 u-overflow-auto-y">
                            <EventInfoModalSubHeader :event="event" />
                            <EventInfoModalBody
                                :event="event" />
                        </div>
                    </template>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import CloseModalOnBreakpointChangeMixin from '@core/mixins/modals/CloseModalOnBreakpointChange';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import HeaderVariant1 from '@tenant/app/components/modals/HeaderVariant1';
    import EventInfoModalSubHeader from './EventInfoModal/EventInfoModalSubHeader.vue';
    import EventInfoModalBody from './EventInfoModal/EventInfoModalBody.vue';

    const STORE_EVENT_NAME = 'events';
    const STORE_PACKAGES_NAME = 'packagesOffersV2';

    export default {
        name: 'EventInfoModal',
        components: {
            HeaderVariant1,
            EventInfoModalSubHeader,
            EventInfoModalBody,
            DataLoading
        },
        mixins: [ CloseModalOnRouteChangeMixin, CloseModalOnBreakpointChangeMixin ],
        props: {
            id: {
                type: [ String, Number ],
                default: null
            }
        },
        data( ) {
            return {
                loading: true,
                packageInfo: null
            };
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.event.eventInfoModal'
        },
        computed: {
            ...mapState( {
                event: state => state[STORE_EVENT_NAME].detail,
                packageDetails: state => state[STORE_PACKAGES_NAME].packageDetails
            } ),
            ...mapGetters( {
                getPackageDetailsByEventId: `${STORE_PACKAGES_NAME}/getDetailsByEventId`,
                hasPackageByEventId: `${STORE_PACKAGES_NAME}/hasByEventId`
            } ),
        },
        watch: {
            'packageDetails': {
                handler: function ( ) {
                    this.packageInfo = this.getPackageDetailsByEventId( );
                },
                deep: true,
                immediate: true
            }
        },
        methods: {
            ...mapActions( {
                loadOne: `${STORE_EVENT_NAME}/one`,
            } )
        },
        async mounted( ) {
            this.loading = true;
            if ( this.event.id !== this.id ) {
                await this.loadOne( this.id );
            }
            this.loading = false;
        }
    };
</script>

<style  lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-event-info-modal {
    border-radius: 0 !important;

    .gst-event-info-modal--no-tabs {
        padding-bottom: 43px;
    }
}
</style>
