<template>
    <v-img v-if="imageSrc"
        ref="image"
        :src="imageSrc"
        class="white--text"
        :gradient="gradient"
        v-bind="$attrs"
        @load="onLoad"
        @error="onError">
        <template v-if="hasPlaceholder" v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center">
                <DataLoading />
            </v-row>
        </template>
        <slot name="content"></slot>
    </v-img>
    <div v-else-if="$slots.fallback">
        <slot name="fallback"></slot>
    </div>
    <v-img v-else class="fallback-image" v-bind="$attrs" />
</template>
<script>
    import {
        hasIncorrectContentSize as imageUtilsHasIncorrectContentSize
    } from '@core/utils/imageUtils';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';

    export default {
        name: 'BaseImage',
        inheritsAttribute: true,
        components: {
            DataLoading
        },
        props: {
            src: {
                type: String,
                default: null
            },
            fallbackSrc: {
                type: String,
                default: null
            },
            gradient: {
                type: String,
                default: null
            },
            hasPlaceholder: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: null
            },
            validateSrcContentSize: {
                type: Boolean,
                default: false
            }
        },
        data: () => ( {
            errorImage: false,
            errorImageFallback: false
        } ),
        computed: {
            /**
             * Image src that is unused on the main v-img ( src or fallbackSrc )
             * On error those will be invalidated
             */
            imageSrc( ) {
                if ( this.src && !this.errorImage  ) {
                    return this.src;
                }

                if ( this.fallbackSrc && !this.errorImageFallback ) {
                    return this.fallbackSrc;
                }
                return null;
            },
            hasSlotFallback( ) {
                return !!this.$slots.fallback;
            }
        },
        watch: {
            'src': function ( ) {
                this.errorImage = false;
                this.errorImageFallback = false;
            },
            'fallbackSrc': function ( ) {
                this.errorImageFallback = false;
            }
        },
        methods: {
            onError( src ) {
                if ( src === this.src ) {
                    this.errorImage = true;
                    this.$emit( 'error' );
                    if ( !this.fallbackSrc ) {
                        this.$emit( 'error-load' );
                    }
                }
                if ( src === this.fallbackSrc ) {
                    this.errorImageFallback = true;
                    this.$emit( 'error-fallback' );
                    this.$emit( 'error-load' );
                }
            },
            onLoad( src ) {
                if ( this.validateSrcContentSize ) {
                    if ( this.src === src ) {
                        this.errorImage = imageUtilsHasIncorrectContentSize( this.$refs.image.image );
                    }
                    if ( this.fallbackSrc === src ) {
                        this.errorImageFallback = imageUtilsHasIncorrectContentSize( this.$refs.image.image );
                    }
                }
                this.$emit( 'load' );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.fallback-image {
    height: 100%;
    width: 100%;
    background: url( '~@tenants/ticketmaster/app/assets/icons/image.png' ) no-repeat center center, rgba( theme-color-hex( 'quaternary' ), 0.2 );
}

</style>